import { Route } from '@amazeelabs/react-framework-bridge';
import {
  buildForm,
  buildHtml,
  buildLink,
} from '@amazeelabs/react-framework-bridge/gatsby';
import { HPageRoute, MenuProvider, ThemeProvider } from '@geored/ui';
import { footerFormSchema } from '@geored/ui/src/utils/footerFormSchema';
import { navigate } from 'gatsby-link';
import React from 'react';

import { SEO } from '../components/SEO';
import Data from '../data/about.json';
import footerMenuItems from '../data/footer_nav.json';
import mainMenuItems from '../data/main_nav.json';
import reshapeMainMenuData from '../utils/reshapeMainMenuData';

// @ts-ignore
export const Head = ({ children }) => (
  <>
    <SEO currentPath={''} title={'GeoRED'} />
    {children}
  </>
);

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
};

const navItemsReshaped = reshapeMainMenuData(mainMenuItems.nav_item);
const footerNavItemsReshaped = reshapeMainMenuData(footerMenuItems.nav_item);

export default function IndexPage() {
  return (
    <ThemeProvider theme={'geored'}>
      <MenuProvider>
        <Route
          definition={HPageRoute}
          input={{
            headerHero: [
              {
                key: 'HeaderHero',
                input: {
                  isSubpage: true,
                  mainMenuItems: navItemsReshaped,
                  bgImage: Data.hero_image,
                  LogoLinkHome: buildLink({
                    href: '/',
                    title: 'geoRED Home page',
                  }),
                  RRPLink: buildLink({
                    href: 'https://coastalbendcog.org/regional-resilience-partnership',
                    title: 'RRP Link',
                  }),
                  title: Data.title,
                  heroBlocks: Data.hero_blocks,
                },
              },
            ],
            body: [
              {
                key: 'imageCta',
                input: {
                  headerArea: buildHtml(
                    Data.fact_section[0].fact_title_text_area,
                  ),
                  subImages: Data.fact_section[0].sub_images,
                  subText: buildHtml(Data.fact_section[0].sub_text),
                  featuredText: buildHtml(Data.fact_section[0].main_text_area),
                },
              },
              {
                key: 'imageCta',
                input: {
                  headerArea: buildHtml(
                    Data.fact_section[1].fact_title_text_area,
                  ),
                  subImages: Data.fact_section[1].sub_images,
                  subText: buildHtml(Data.fact_section[1].sub_text),
                  featuredText: buildHtml(Data.fact_section[1].main_text_area),
                  classes: 'py-20 !my-0 bg-teal-50',
                },
              },
            ],
            footer: {
              linkedInUrl: 'https://www.linkedin.com/company/coastal-bend-rrp/',
              instagramUrl: 'https://www.instagram.com/coastalbend_rrp/',
              facebookUrl: 'https://www.facebook.com/coastalbend.rrp/',
              footerText:
                'Welcome to GeoRED, a geospatial data portal designed to assist the communities of the Coastal Bend, Texas in practicing smart growth practices by providing regional hazard, modeling, and economic development data.',
              ButtonLink: buildLink({
                href: '/contact',
                title: 'Get in Touch',
              }),
              mainNav: navItemsReshaped,
              footerNav: footerNavItemsReshaped,
              upperFooterSubtitle:
                'Send us your email address and we’ll get in touch',
              upperFooterTitle: 'Send us a Message',
              fundingText:
                'This project was funded by the U.S. Department of Commerce Economic Development Administration and the Texas OneGulf RESTORE Center of Excellence, with federal funding from the Department of the Treasury through the State of Texas under the Resources and Ecosystems Sustainability, Tourist Opportunities, and Revived Economies of the Gulf Coast States Act of 2012 (RESTORE Act). The content, statements, findings, opinions, conclusions, and recommendations are those of the author(s) and do not necessarily reflect the views of the State of Texas or the Treasury.',
              partnershipText:
                'The Regional Resilience Partnership (RRP) is a joint initiative of the Coastal Bend Council of Governments and the Harte Research Institute at Texas A&M University-Corpus Christi.',
              Form: buildForm({
                initialValues: { email: '' },
                validationSchema: footerFormSchema,
                onSubmit(values) {
                  fetch('/', {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: encode({
                      'form-name': 'follow-up',
                      ...values,
                    }),
                  })
                    .then(() => navigate('/thank-you'))
                    .catch((error) => alert(error));
                },
              }),
              FormText: buildHtml(
                `We care about the protection of your date. Read our <a href="#">Privacy Policy</a>`,
              ),
            },
          }}
          intl={{ defaultLocale: 'en', locale: 'en' }}
        ></Route>
      </MenuProvider>
    </ThemeProvider>
  );
}
